import "./App.css";
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import Signup from './Pages/Signup';
import Login from './Components/Login/Login';
import Footer from './Components/Footer/Footer';
import powder_masala_banner from './Components/Assets/powder_masala.png';
import khada_masala_banner from './Components/Assets/khada_masala.png';
import dry_fruits_banner from './Components/Assets/dry_fruits.png';
import BlogDetail from './Pages/Blog/BlogDetail';
import BlogPage from './Pages/Blog/BlogPage';
import Scroller from './Components/Scroller/Scroller';
import AddItems from './Pages/AddItems';
import ScrollingUp from './Components/ScrollingUp/ScrollingUp';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import ContactUs from "./Components/ContactUs/ContactUs";
import Query from "./Components/Query/Query";
import AboutUs from "./Components/AboutUs/AboutUs";
import CustomerReviews from "./Components/CustomerReviews/CustomerReviews";
import NotFound from "./Components/NotFound/NotFound";

const Layout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <>
      {isHomePage && <Scroller />}
      <Navbar isHomePage={isHomePage} />
      {children}
    </>
  );
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Layout>
          <ScrollingUp />
          <Routes>
            <Route path="/" element={<Shop />} />
            <Route
              path="/organic-khada-masala"
              element={<ShopCategory banner={khada_masala_banner} category_id={1} />}
            />
            <Route
              path="/organic-powdered-masala"
              element={<ShopCategory banner={powder_masala_banner} category_id={2} />}
            />
            <Route
              path="/premium-dry-fruits-berries"
              element={<ShopCategory banner={dry_fruits_banner} category_id={3} />}
            />
            <Route path="/add_items" element={<AddItems />} />
            <Route path="/product/:productId" element={<Product />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/query" element={<Query />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/customer-reviews" element={<CustomerReviews />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/not-found" element={<NotFound/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
          <Footer />
        </Layout>
        <Analytics />
        <SpeedInsights />
      </BrowserRouter>
    </div>
  );
}

export default App;
