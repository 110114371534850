import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import Logo from '../Assets/logo1.PNG';
import axios from 'axios';
import jaipur_masale from '../Assets/jaipur_masale.png'


const Login = () => {
  const [formData, setFormData] = useState({
    countryCode: '',
    mobileNumber: '',
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formErrors = {};
    if (!formData.countryCode) formErrors.countryCode = 'Please enter your country code';
    if (!formData.mobileNumber) formErrors.mobileNumber = 'Please enter your mobile number';

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await axios.post('https://jaipurmasale-backend.onrender.com/api/login/',
          {
            country_code: formData.countryCode,
            mobile_number: formData.mobileNumber,
          },
        );

        const token = response.data.token;
        console.log('Token received:', token);

        // Store the token in localStorage
        localStorage.setItem('token', token);

        // Dispatch storage event to notify other components (if needed)
        window.dispatchEvent(new Event('storage'));

        // Navigate to the home page after successful login
        navigate('/');

      } catch (error) {
        console.error('Login failed', error);
        setErrors({ apiError: 'Login failed. Please try again.' });
      }
    }
  };

  return (
    <div className='login'>
      <div className="login-container">
        <div className='logo'>
          <img src={jaipur_masale} alt="Logo" />
        </div>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="login-fields">
            <div className="input-group">
              <input
                type="text"
                name="countryCode"
                placeholder="Country Code"
                value={formData.countryCode}
                onChange={handleChange}
                className={`country-code ${errors.countryCode ? 'error' : ''}`}
              />
              <input
                type="text"
                name="mobileNumber"
                placeholder="Mobile Number"
                value={formData.mobileNumber}
                onChange={handleChange}
                className={`mobile-number ${errors.mobileNumber ? 'error' : ''}`}
              />
            </div>
            {errors.countryCode && <p className="error-message">{errors.countryCode}</p>}
            {errors.mobileNumber && <p className="error-message">{errors.mobileNumber}</p>}
          </div>
          {errors.apiError && <p className="error-message">{errors.apiError}</p>}
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;



// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import './Login.css';
// import Logo from '../Assets/logo1.PNG';
// import axios from 'axios';

// const Login = () => {
//   const [formData, setFormData] = useState({
//     email: '',
//     // password: '',
//   });

//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();


//     let formErrors = {};
//     if (!formData.email) formErrors.email = 'Please enter your email';
//     // if (!formData.password) formErrors.password = 'Please enter your password';

//     setErrors(formErrors);

//     if (Object.keys(formErrors).length === 0) {
//       try {
//         const response = await axios.get('https://jaipurmasale-backend.onrender.com/api/login/', {
//           params: { email: formData.email },
//         });

//         const token = response.data.token;
//         console.log('Token received:', token);


//         // Store the token in localStorage
//         localStorage.setItem('token', token);

//         // Dispatch storage event to notify other components (Navbar)
//         window.dispatchEvent(new Event('storage'));

//         navigate('/');



//       } catch (error) {
//         console.error('Login failed', error);
//         setErrors({ apiError: 'Login failed. Please Signup.' });
//       }
//     }
//   };

//   return (
//     <div className='login'>
//       <div className="login-container">
//         <div className='logo'>
//           <img src={Logo} alt="Logo" />
//         </div>
//         <h1>Login</h1>
//         <form onSubmit={handleSubmit}>
//           <div className="login-fields">
//             <input
//               type="email"
//               name="email"
//               placeholder='Email Address'
//               value={formData.email}
//               onChange={handleChange}
//               className={errors.email && 'error'}
//             />
//             {errors.email && <p className="error-message">{errors.email}</p>}
//             {/* <input
//               type="password"
//               name="password"
//               placeholder='Password'
//               value={formData.password}
//               onChange={handleChange}
//               className={errors.password && 'error'}
//             />
//             {errors.password && <p className="error-message">{errors.password}</p>} */}
//           </div>
//           {errors.apiError && <p className="error-message">{errors.apiError}</p>}
//           <button type="submit">Login</button>
//         </form>
//         {/* <p className="login-signup">
//           Don't have an account? <Link to="/signup"><span>Sign up here</span></Link>
//         </p> */}
//       </div>
//     </div>
//   );
// };

// export default Login;
