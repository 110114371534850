import React from 'react'
import './Footer.css'
import jaipur_masale from '../Assets/jaipur_masale.png'

import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, YoutubeOutlined, WhatsAppOutlined, AmazonOutlined, XOutlined } from '@ant-design/icons'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-logo">
        <img src={jaipur_masale} alt="Logo" />
      </div>
      <ul className="footer-links">
        <li>Company</li>
        <li>Products</li>
        <li>Offices</li>
        <li>About</li>
        <li>Contact</li>
      </ul>
      <div className="footer-social-icon">
          <div className="footer-icons-container">
              <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/jaipurmasale/'>
                  <InstagramOutlined style={{ fontSize: 25 }} />
              </a>
          </div>
          <div className="footer-icons-container">
              <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/@garvtambi5786/'>
                  <YoutubeOutlined style={{ fontSize: 25 }} />
              </a>
          </div>
          <div className="footer-icons-container">
              <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/jaipur-masale-a176721a8/'>
                  <LinkedinOutlined style={{ fontSize: 25 }} />
              </a>
          </div>
          <div className="footer-icons-container">
              <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/jaipurmasale/'>
                  <FacebookOutlined style={{ fontSize: 25 }} />
              </a>
          </div>
          <div className="footer-icons-container">
              <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/jaipurmasale/'>
                  <WhatsAppOutlined style={{ fontSize: 25 }} />
              </a>
          </div>
		  <div className="footer-icons-container">
              <a target='_blank' rel='noopener noreferrer' href='https://x.com/garv_tambi'>
                  <XOutlined style={{ fontSize: 25 }} />
              </a>
          </div>
          <div className="footer-icons-container">
              <a target='_blank' rel='noopener noreferrer' href='https://www.amazon.in/stores/Shaan-E-Jaipur/page/C0CB0EE5-746F-4F83-B553-F9838B4DA3CB?ref_=ast_bln'>
                  <AmazonOutlined style={{ fontSize: 25 }} />
              </a>
          </div>
      </div>
      <div className="footer-copyright">
        <hr />
        <p>Copyright @ 2024 - All Right Reserved.</p>
      </div>
    </div>
  )
}

export default Footer
