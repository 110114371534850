// 1 import React, { createContext, useState } from "react";
// import all_product from "../Components/Assets/all_product";

// export const ShopContext =  createContext(null);

// const getDefaultCart = ()=>{
//     let cart = {};
//     for (let index = 0; index < all_product.length+1; index++) {
//         cart[index] = 0;
//     }
//     return cart;
// }

// const ShopContextProvider = (props) => {

//     const [cartItems,setCartItems] = useState(getDefaultCart());


//     const addToCart = (itemId) =>{
//         setCartItems((prev)=>({...prev,[itemId]:prev[itemId]+1}));
//         console.log(cartItems);
//     }

//     const removeFromCart = (itemId) =>{
//         setCartItems((prev)=>({...prev,[itemId]:prev[itemId]-1}))
//     }

//     const getTotalCartAmount = () => {
//         let totalAmount = 0;
//         for (const item in cartItems) {
//           if (cartItems[item] > 0) {
//             let itemInfo = all_product.find((product) => product.id === Number(item));
//             totalAmount += cartItems[item] * itemInfo.new_price;
//           }
//         }
//         return totalAmount;
//       }

//       const getTotalCartItems = () =>{
//         let totalItem = 0;
//         for(const item in cartItems)
//         {
//             if(cartItems[item]>0)
//             {
//                 totalItem+= cartItems[item];
//             }
//         }
//         return totalItem;
//       }

//     const contextValue = {getTotalCartItems,getTotalCartAmount,all_product,cartItems,addToCart,removeFromCart};
//     return (
//         <ShopContext.Provider value={contextValue}>
//             {props.children}
//         </ShopContext.Provider>
//     )
// }

// export default ShopContextProvider;


import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {
    const [cartItems, setCartItems] = useState([]);
    const token = localStorage.getItem('token');

    // Fetch cart items from the backend
    const fetchCartItems = async () => {
        try {
            const response = await axios.get('https://jaipurmasale-backend.onrender.com/api/get-cart-items/', {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            setCartItems(response.data.data);
            console.log("fetch cart items", response.data.data)
        } catch (error) {
            console.error("Error fetching cart items:", error);
        }
    };

    // Fetch cart items on component mount
    useEffect(() => {
        if (token) {
            fetchCartItems();
        }
    }, [token]);

    // Add an item to the cart
    const addToCart = async (product_pk, size, quantity, offer_price, mrp) => {
        try {
            const response = await axios.post(
                'https://jaipurmasale-backend.onrender.com/api/cart-items/',
                {
                    product_pk,
                    size,
                    quantity,
                    offer_price,
                    mrp,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            fetchCartItems();  // Refresh cart items after adding
            console.log("add to cart", response.data.data)
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    };

    // Remove an item from the cart (quantity adjustment or removal)
    const removeFromCart = async (product_pk, size, offer_price, mrp) => {
        try {
            const response = await axios.post(
                'https://jaipurmasale-backend.onrender.com/api/remove-cart-items/',
                {
                    product_pk,
                    size,
                    offer_price,
                    mrp,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );

            // After removing the item, fetch the updated cart items from the backend
            fetchCartItems();
            console.log("Item removed:", response.data);
        } catch (error) {
            console.error("Error removing item from cart:", error);
        }
    };



    // Get the total amount in the cart
    const getTotalCartAmount = () => {
        let totalAmount = 0;
        cartItems.forEach((item) => {
            totalAmount += item.quantity * item.offer_price;
        });
        return totalAmount;
    };

    // Get the total number of items in the cart
    const getTotalCartItems = () => {
        let totalItems = 0;
        cartItems.forEach((item) => {
            totalItems += item.quantity;
        });
        return totalItems;
    };

    const clearCartItems = () => {
        setCartItems([]);
    };

    const contextValue = {
        getTotalCartItems,
        getTotalCartAmount,
        cartItems,
        addToCart,
        removeFromCart,
        clearCartItems,
        fetchCartItems,
    };

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    );
};

export default ShopContextProvider;
