import React from "react";
import { Helmet } from "react-helmet";
import "./AboutUs.css";
import Image from "../Assets/Image_cart.jpg";

const AboutUs = () => {
  return (
    <div className="about">
      <Helmet>
        <link rel="canonical" href="https://jaipurmasale.com/about-us" />
      </Helmet>
      <h2 className="about-title">ABOUT US</h2>
      <hr/>
      <div className="about-container">
        <div className="about-content">
          <p className="about-text">
            <strong>Jaipur Masale & Dry Fruits</strong> brings you the purest flavors, backed by research, innovation, and technology. Our success lies in capturing the essence of India's rich culinary heritage with our premium dry fruits and perfect blends.
          </p>
          <p className="about-text">
            What sets us apart? We carefully select and process our dry fruits to retain their <strong>natural aroma, flavor, and nutritional value</strong>. Experience the taste of authenticity and let your passion for Indian cuisine thrive.
          </p>
          <p className="about-signature">
            Sincerely,
            <br />
            <strong>Garv Tambi</strong>
            <br />
            Founder, Jaipur Masale & Dry Fruits
          </p>
        </div>
        <div className="about-image">
          <img src={Image} alt="About ShopEase" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
