import React from 'react';
import './NotFound.css';
import { Helmet } from 'react-helmet'; // Ensure react-helmet is installed

const NotFound = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "404 - Page Not Found",
    "description": "This page does not exist. Please return to the homepage or use the search bar.",
    "mainEntityOfPage": "https://www.jaipurmasale.com/404"
  };

  return (
    <div>
      <Helmet>
        {/* <title>404 - Page Not Found</title> */}
        <meta name="robots" content="noindex, follow" />
        {/* Add JSON-LD structured data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <h1 className='not-found-page'>Oops! Page Not Found</h1>
      <p className='not-found-description'>We couldn't find the page you were looking for. <a href="/"><b>Go back to the homepage</b></a></p>
    </div>
  );
};

export default NotFound;
