import React, { useEffect, useState } from 'react';
import './CSS/Product.css';
import { useParams } from 'react-router-dom';
import Breadcrum from '../Components/Breadcrums/Breadcrum';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox';
import RelatedProducts from '../Components/RelatedProducts/RelatedProducts';
import ContactUs from '../Components/ContactUs/ContactUs';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import NotFound from '../Components/NotFound/NotFound';

const Product = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [selectedSize, setSelectedSize] = useState('S');
  const [error, setError] = useState(null);

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`https://jaipurmasale-backend.onrender.com/api/get-product-detail/?product_pk=${productId}&size=${selectedSize}`);
        setProductData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching product data:', error);
        setError('Failed to load product data. Please try again later.');
      }
    };

    fetchProductData();
  }, [productId, selectedSize]);

  if (error) {
    return <NotFound/>;
  }

  if (!productData) {
    return (
      <div className="loading-spinner">
        <p>Loading...</p>
      </div>
    );
  }

  const { product_code, name = "Product", description = "Product description not available." } = productData.data; // Provide defaults
  const related_products = productData.related_product.filter(
    (product) => product.product_code !== product_code
  );

  return (
    <div>
      <Helmet>
        <title>{name} - Jaipur Masale</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://jaipurmasale.com/product/${productId}`} />
      </Helmet>
      <Breadcrum product={productData.data} />
      <ProductDisplay
        product={productData.data}
        selectedSize={selectedSize}
        onSizeChange={handleSizeChange}
      />
      <RelatedProducts relatedProducts={related_products} />
      <DescriptionBox product={productData.data} />
      <ContactUs />
    </div>
  );
};

export default Product;
