// import React, { useContext } from 'react'
// import './CSS/ShopCategory.css'
// import { ShopContext } from '../Context/ShopContext'
// import dropdown_icon from '../Components/Assets/dropdown_icon.png'
// import Item from '../Components/Item/Item'

// const ShopCategory = (props) => {
//   const {all_product} = useContext(ShopContext);
//   return (
//     <div className='shop-category'>
//       <img className='shopcategory-banner' src={props.banner} alt="" />
//       <div className="shopcategory-indexSort">
//         <p>
//           <span>Showing 1-12</span> out of 36 products
//         </p>
//         <div className="shopcategory-sort">
//           Sort by <img src={dropdown_icon} alt="" />
//         </div>
//       </div>
//       <div className="shopcategory-products">
//         {all_product.map((item,i)=>{
//           if (props.category===item.category) {
//             return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}/>
//           }
//           else{
//             return null;
//           }
//         })}
//       </div>
//       <div className="shopcategory-loadmore">
//         Explore More
//       </div>
//     </div>
//   )
// }

// export default ShopCategory


import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './CSS/ShopCategory.css';
import axios from 'axios';
import Item from '../Components/Item/Item';
import dropdown_icon from '../Components/Assets/dropdown_icon.png';
import ContactUs from '../Components/ContactUs/ContactUs';

const ShopCategory = ({ banner, category_id }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const response = await axios.get(`https://jaipurmasale-backend.onrender.com/api/get-all-products/`, {
          params: { category_pk: category_id },
        });
        setProducts(response.data.data);
        setError(null); // Clear any previous error
      } catch (error) {
        console.error('Error fetching category products:', error);
        setError('Failed to load products. Please try again later.');
      } finally {
        setLoading(false); // Set loading to false after fetching completes
      }
    };

    fetchCategoryProducts();
  }, [category_id]);

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "ProductCollection",
    "name": "Shop Category",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "itemListElement": products.map((product) => ({
        "@type": "Product",
        "name": product.product_name,
        "image": product.main_image,
        "description": product.description || "Quality spices and products from Jaipur Masale",
        "offers": {
          "@type": "Offer",
          "priceCurrency": "INR",
          "price": product.offer_price,
          "itemCondition": "https://schema.org/NewCondition",
          "availability": product.is_active ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
          "url": `https://www.jaipurmasale.com/product/${product.id}`
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": product.rating,
          "reviewCount": product.review_count || 0
        }
      }))
    }
  };

  if (loading) {
    return <div className="loading-spinner">Loading products...</div>; // Show loading state
  }

  if (error) {
    return <div className="error-message">{error}</div>; // Show error message
  }

  return (
    <div className='shop-category'>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
      <img className='shopcategory-banner' src={banner} alt="" />
      <div className="shopcategory-indexSort">
        <p>
          <span>Showing 1-{Math.min(12, products.length)}</span> out of {products.length} products
        </p>
        <div className="shopcategory-sort">
          Sort by <img src={dropdown_icon} alt="" />
        </div>
      </div>
      <div className="shopcategory-products">
        {products
          .filter(item => item.is_active) // Only show active products
          .map((item) => (
            <Item
              key={item.id}
              id={item.id}
              name={item.product_name}
              image={item.main_image}
              new_price={item.offer_price}
              old_price={item.mrp}
              rating={item.rating}
            />
          ))}
      </div>
      <div className="shopcategory-loadmore" onClick={() => console.log('Load more products')}>
        Explore More
      </div>
      <ContactUs />
    </div>
  );
};

export default ShopCategory;
