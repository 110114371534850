import React from 'react'
import Hero from '../Components/Hero/Hero'
import Popular from '../Components/Popular/Popular'
// import Offers from '../Components/Offers/Offers'
import NewCollections from '../Components/NewCollections/NewCollections'
// import NewsLetter from '../Components/NewsLetter/NewsLetter'
import CustomerReviews from '../Components/CustomerReviews/CustomerReviews'
import AboutUs from '../Components/AboutUs/AboutUs'
import ContactUs from '../Components/ContactUs/ContactUs'
// import Scroller from '../Components/Scroller/Scroller'
import Query from '../Components/Query/Query'
import { Helmet } from 'react-helmet'

const Shop = () => {

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Jaipur Masale - Premium Spices",
    "url": "https://www.jaipurmasale.com/",
    "description": "Explore the best quality spices from Jaipur Masale. Authentic and organic spices that enhance your cooking.",
    "publisher": {
      "@type": "Organization",
      "name": "Jaipur Masale",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.jaipurmasale.com/logo.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.jaipurmasale.com/"
    }
  };

  const organizationData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Jaipur Masale",
    "url": "https://www.jaipurmasale.com/",
    "logo": "https://www.jaipurmasale.com/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91 8764576256",
      "contactType": "Customer Service"
    },
    "sameAs": [
      "https://www.facebook.com/jaipurmasale",
      "https://www.instagram.com/jaipurmasale"
    ]
  };


  return (
    <div>
      <Helmet>
        <title>Jaipur Masale - Buy Organic Indian Spices & Dry Fruits</title>
        <meta name="description" content="Discover the best quality natural authentic Indian spices and premium dry fruits from Jaipur Masale. Shop the best flavors today! Online Masala Free Delivery" />
        {/* Structured Data for WebPage */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        {/* Structured Data for Organization */}
        <script type="application/ld+json">
          {JSON.stringify(organizationData)}
        </script>
      </Helmet>


      {/* <Scroller/> */}
      <Hero/>
      <Popular/>
      <NewCollections/>
      <Query/>
      <AboutUs/>
      <CustomerReviews/>
      <ContactUs/>
    </div>
  )
};

export default Shop;
